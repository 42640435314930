/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

/** @namespace Eduksiazka/Component/MyAccountAddressTable/Table/getAddressTablePairArray */
export const getAddressTablePairArray = (props) => {
    const { address } = props;

    return [{
        key: 'lastname',
        label: __('Company'),
        source: address
    },
    {
        key: 'vat_id',
        label: __('VAT ID'),
        source: address
    },
    {
        key: 'firstname',
        label: __('Your name'),
        source: address
    },
    {
        key: 'street',
        label: __('Street'),
        source: address
    },
    {
        key: 'postcode',
        label: __('Postcode'),
        source: address
    },
    {
        key: 'city',
        label: __('City'),
        source: address
    }
    ];
};

export default getAddressTablePairArray;
