/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { VALIDATION_INPUT_TYPE, VALIDATION_RULES as SOURCE_VALIDATION_RULES } from 'SourceUtil/Validator/Config';

export * from 'SourceUtil/Validator/Config';

export const VALIDATION_RULES = {
    ...SOURCE_VALIDATION_RULES,
    [VALIDATION_INPUT_TYPE.phone]: /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{3,6}$/im
};
