/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import './CreateAccountContactInfo.style';

/** @namespace Eduksiazka/Component/CreateAccountContactInfo/Component */
export class CreateAccountContactInfo extends PureComponent {
    static propTypes = {
        id: PropTypes.string
    };

    static defaultProps = {
        id: null
    };

    render() {
        const { id } = this.props;

        return (
            <>
                <h4 id={ id }>{ __("Don't have an account?") }</h4>
                <Link
                  className="Button_likeLink"
                  to="/contact"
                >
                    { __('Contact us') }
                </Link>
            </>
        );
    }
}

export default CreateAccountContactInfo;
