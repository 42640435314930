/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Field } from 'Util/Query';

/** @namespace Eduksiazka/Query/CustomerProductList/Query */
export class CustomerProductListQuery {
    getAddProductToListMutation(input) {
        return new Field('addProductToList')
            .addArgument('input', 'ProductAvailabilityItemInput', input)
            .addFieldList(this._getCustomerProductsListFields());
    }

    getAddProductsToListMutation(skus) {
        return new Field('addProductsToList')
            .addArgument('input', '[ProductAvailabilityItemInput]', skus)
            .addFieldList(this._getCustomerProductsListFields());
    }

    getRemoveProductsFromCart(itemIds) {
        return new Field('removeItemsFromCart')
            .addArgument('quote_items_ids', 'String!', itemIds);
    }

    getDeleteProductFromListMutation(input) {
        return new Field('deleteProductFromList')
            .addArgument('ids', 'String!', input);
    }

    getCustomerProductsListQuery() {
        return new Field('getCustomerProductsList')
            .addFieldList(this._getCustomerProductsListFields());
    }

    getProductListCount() {
        return new Field('countProductsInList');
    }

    _getCustomerProductsListFields() {
        return [
            'entity_id',
            'sku',
            'parent_sku',
            'available',
            this._getProductFields(),
            this._getParentProductFields()
        ];
    }

    _getProductFields() {
        return new Field('product')
            .addField('name');
    }

    _getParentProductFields() {
        return new Field('parent_product')
            .addField('name');
    }
}

export default new CustomerProductListQuery();
