/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import ExpandableContent from 'Component/ExpandableContent';
import MyAccountTabListItem from 'Component/MyAccountTabListItem';
import { MyAccountTabList as SourceMyAccountTabList } from 'SourceComponent/MyAccountTabList/MyAccountTabList.component';

import './MyAccountTabList.override.style';

/** @namespace Eduksiazka/Component/MyAccountTabList/Component */
export class MyAccountTabList extends SourceMyAccountTabList {
    renderLine() {
        return (
            <div block="MyAccountTabList" elem="Separator" />
        );
    }

    renderTabListItem(tabEntry, index, tabArray) {
        const { activeTab, onTabClick, productsOnList } = this.props;
        const [key, tab] = tabEntry;
        const { section } = tab;
        const nextTab = tabArray[index + 1] || [];
        const { section: nextTabSection = section } = nextTab[1] || {};

        return (
            <MyAccountTabListItem
              key={ key }
              isActive={ activeTab === key }
              changeActiveTab={ onTabClick }
              tabEntry={ tabEntry }
              productsOnList={ productsOnList }
            >
                { nextTabSection !== section ? this.renderLine() : null }
            </MyAccountTabListItem>
        );
    }

    renderLogoutTab() {
        const { handleLogout } = this.props;

        return (
            <li
              key="logout"
              block="MyAccountTabListItem"
              mods={ { isLogout: true } }
            >
                <button
                  block="MyAccountTabListItem"
                  elem="Button"
                  mix={ { block: 'Button', mods: { isHollow: true } } }
                  onClick={ handleLogout }
                  role="link"
                >
                    { __('Logout') }
                </button>
            </li>
        );
    }

    render() {
        const {
            activeTab,
            isContentExpanded,
            tabMap,
            device: { isMobile } = {},
            toggleExpandableContent
        } = this.props;
        const { tabName } = tabMap[activeTab];

        if (isMobile) {
            // tablist is handled by separate route MyAccountMenuPage on mobile
            return null;
        }

        const tabs = [
            ...Object.entries(tabMap).map(this.renderTabListItem.bind(this)),
            this.renderLogoutTab()
        ];

        return (
            <ExpandableContent
              heading={ tabName }
              isContentExpanded={ isContentExpanded }
              mix={ { block: 'MyAccountTabList' } }
              onClick={ toggleExpandableContent }
              mods={ { isWithoutBorder: true } }
            >
                <ul>
                    { tabs }
                </ul>
            </ExpandableContent>
        );
    }
}

export default MyAccountTabList;
