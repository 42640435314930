/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import ExpandableContent from 'Component/ExpandableContent';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import MyAccountOrderStatus from 'Component/MyAccountOrderStatus';
import { ACCOUNT_ORDER_PRINT_URL } from 'Route/MyAccount/MyAccount.config';
import { MyAccountOrder as SourceMyAccountOrder } from 'SourceComponent/MyAccountOrder/MyAccountOrder.component';
import { formatDateWithSlashes } from 'Util/Date/Date';
import { getTimeInCurrentTimezone } from 'Util/Manipulations/Date';
import { formatPrice } from 'Util/Price/Price';
import { appendWithStoreCode } from 'Util/Url';

import { ReactComponent as PrinterIcon } from '../../assets/icons/printer.svg';
import {
    ORDER_ITEMS
} from './MyAccountOrder.config';

import './MyAccountOrder.override.style';

/** @namespace Eduksiazka/Component/MyAccountOrder/Component */
export class MyAccountOrder extends SourceMyAccountOrder {
    state = {
        currency: 'PLN'
    };

    renderOrderItemsTable() {
        return null;
    }

    renderCustomOrderItemsTableRow(item = {}) {
        const {
            id,
            openqty,
            quantity,
            reservedqty,
            itemname,
            pricebefdi,
            linetotal,
            itemcode,
            discprcnt,
            price,
            priceafvat
        } = item;

        const { isMobile } = this.props;

        const { currency } = this.state;

        const totalNetto = price * quantity;
        const taxTotalAmount = linetotal - totalNetto;

        const repeatedContent = (
            <>
                <td>
                    <div>
                        { isMobile && <span>{ __('Quantity') }</span> }
                        <span>{ quantity }</span>
                    </div>
                </td>
                <td>
                    <div>
                        { isMobile && (
                            <span>
                                { __('Reserved') }
                                <span>
                                    { `${__('/Undone')}` }
                                </span>
                            </span>
                        ) }
                        <span>{ `${reservedqty} / ${openqty}` }</span>
                    </div>
                </td>
                <td>
                    <div>
                        { isMobile && <span>{ __('Net price') }</span> }
                        <div
                          block="MyAccountOrder"
                          elem="ProductsTableDiscountWrapper"
                        >
                            <div
                              block="MyAccountOrder"
                              elem="ProductsTableOldPrice"
                            >
                                { pricebefdi ? formatPrice(pricebefdi, currency) : '' }
                            </div>
                            { discprcnt && (
                                <div
                                  block="MyAccountOrder"
                                  elem="ProductsTableDiscount"
                                >
                                    { __('discount') }
                                    { ' ' }
                                    { `${discprcnt}%` }
                                </div>
                            ) }
                            <div
                              block="MyAccountOrder"
                              elem="ProductsTablePromoPrice"
                            >
                                { price ? formatPrice(price, currency) : '' }
                            </div>

                        </div>
                    </div>

                </td>

                <td>
                    <div>
                        { isMobile && <span>{ __('Gross price with discount') }</span> }
                        <span>{ priceafvat ? formatPrice(priceafvat, currency) : '' }</span>
                    </div>
                </td>
                <td>
                    <div>
                        { isMobile && <span>{ __('Net sum') }</span> }
                        <span>{ totalNetto ? formatPrice(totalNetto, currency) : '' }</span>
                    </div>
                </td>
                <td>
                    <div>
                        { isMobile && <span>{ __('Vat value') }</span> }
                        <span>{ taxTotalAmount ? formatPrice(taxTotalAmount, currency) : '' }</span>
                    </div>
                </td>
                <td>
                    <div>
                        { isMobile && <span>{ __('Gross sum') }</span> }
                        <span>{ linetotal ? formatPrice(linetotal, currency) : '' }</span>
                    </div>
                </td>

            </>
        );

        if (isMobile) {
            return (
                <tr>
                    <ExpandableContent heading={ (
                        <>
                            { itemname }
                            <div
                              block="MyAccountOrder"
                              elem="ProductsTableSku"
                            >
                                { itemcode }

                            </div>
                        </>
                    ) }
                    >
                        { repeatedContent }
                    </ExpandableContent>
                </tr>
            );
        }

        return (
            <tr key={ id }>
                <td>
                    { itemname }
                    <div
                      block="MyAccountOrder"
                      elem="ProductsTableSku"
                    >
                        { itemcode }

                    </div>
                </td>
                { repeatedContent }
            </tr>
        );
    }

    renderCustomOrderItemsTable() {
        const {
            order: {
                items
            },
            isMobile
        } = this.props;

        if (!items || items.length === 0) {
            return null;
        }

        return (
            <div block="MyAccountOrder" elem="ProductsTableWrapper">
                <h3
                  block="MyAccountOrder"
                  elem="ProductsTableHeading"
                >
                    { __('Ordered products') }
                </h3>
                <table
                  block="MyAccountOrder"
                  elem="ProductsTable"
                >
                    { !isMobile && (
                        <thead>
                            <tr>
                                <th>{ __('Products') }</th>
                                <th>{ __('Quantity') }</th>
                                <th>{ __('Reserved/Undone') }</th>
                                <th>{ __('Net price') }</th>
                                <th>{ __('Gross price with discount') }</th>
                                <th>{ __('Net sum') }</th>
                                <th>{ __('Vat value') }</th>
                                <th>{ __('Gross sum') }</th>
                            </tr>
                        </thead>
                    ) }
                    <tbody>
                        { items?.map((item) => this.renderCustomOrderItemsTableRow(item)) }
                    </tbody>
                </table>
            </div>
        );
    }

    renderAdditionalInfoTable() {
        const {
            order: {
                docnum,
                idzewn,
                docdate,
                status,
                doctotalnet,
                doctotal,
                doccur,
                vatsum
            },
            isMobile
        } = this.props;

        this.setState({
            currency: doccur
        });

        return (
            <table
              block="MyAccountOrderItemsTable"
              elem="AdditionalInfo"
            >
                <thead>
                    <tr>
                        <th>{ __('Document nr') }</th>
                        { !isMobile && <th>{ __('External order nr') }</th> }
                        <th>{ __('Order date') }</th>
                        <th>{ __('Status') }</th>
                        { !isMobile && <th>{ __('Net value') }</th> }
                        { !isMobile && <th>{ __('Vat value') }</th> }
                        { !isMobile && <th>{ __('Gross value') }</th> }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{ docnum }</td>
                        { !isMobile && <td>{ idzewn }</td> }
                        <td>{ docdate ? formatDateWithSlashes(docdate) : '' }</td>
                        <td>
                            <MyAccountOrderStatus status={ status } />
                        </td>
                        { !isMobile && <td>{ doctotalnet ? formatPrice(doctotalnet, doccur) : '' }</td> }
                        { !isMobile && <td>{ vatsum ? formatPrice(vatsum, doccur) : '' }</td> }
                        { !isMobile && <td>{ doctotal ? formatPrice(doctotal, doccur) : '' }</td> }
                    </tr>
                </tbody>
            </table>
        );
    }

    renderPrintInvoice() {
        const { order: { invoice_url } } = this.props;

        if (!invoice_url) {
            return null;
        }

        return (
            <a
              href={ invoice_url }
              download
              target="_blank"
              rel="noreferrer"
            >
                { __('Print Invoice') }
            </a>
        );
    }

    renderCustomOrderTotals() {
        const {
            order: {
                doctotalnet,
                vatsum,
                doctotal
            } = {}
        } = this.props;
        const { currency } = this.state;
        return (
            <div block="MyAccountOrder" elem="Totals">
                <div>
                    <div block="MyAccountOrder" elem="TotalsHeading">{ __('Summary') }</div>
                    <div>
                        <span>{ __('net value') }</span>
                        <span>{ doctotalnet ? formatPrice(doctotalnet, currency) : '' }</span>
                    </div>
                    <div>
                        <span>{ __('vat value') }</span>
                        <span>{ vatsum ? formatPrice(vatsum, currency) : '' }</span>
                    </div>
                    <div>
                        <span>{ __('gross value') }</span>
                        <span>{ doctotal ? formatPrice(doctotal, currency) : '' }</span>
                    </div>
                </div>
            </div>
        );
    }

    renderReorderButton() {
        const { handleReorder } = this.props;

        return (
            <button
              block="Button"
              mods={ { isHollow: true } }
              mix={ { block: 'MyAccountOrder', elem: 'Reorder' } }
              onClick={ handleReorder }
            >
                { __('Reorder') }
            </button>
        );
    }

    renderExternalOrderInformation() {
        const {
            order: {
                idzewn
            },
            isMobile
        } = this.props;

        if (!isMobile) {
            return null;
        }

        return (
            <div block="MyAccountOrder" elem="ExternalOrder">
                <span>{ __('External order nr') }</span>
                <span>{ idzewn }</span>
            </div>
        );
    }

    renderOrderComments() {
        const { activeTab, order: { comments = [] } } = this.props;

        if (activeTab !== ORDER_ITEMS || !comments || !comments.length || !Array.isArray(comments)) {
            return null;
        }

        return (
            <div block="MyAccountOrder" elem="Comments">
                <div
                  block="MyAccountOrder"
                  elem="CommentsTitle"
                >
                    { __('About Your Order') }
                </div>
                <div block="MyAccountOrder" elem="CommentsList">
                    { comments.map(({ timestamp, message }) => (
                        <dl
                          block="MyAccountOrder"
                          elem="Comment"
                          key={ `${activeTab}-comment-${timestamp}` }
                        >
                            <dt>{ getTimeInCurrentTimezone(timestamp) }</dt>
                            <dd>{ message }</dd>
                        </dl>
                    )) }
                </div>
            </div>
        );
    }

    renderActions() {
        return (
            <div block="MyAccountOrder" elem="Actions">
                { /* <div block="MyAccountOrder" elem="Buttons"> */ }
                    { /* { this.renderPrintInvoice() } */ }
                    { /* this.renderPrintOrder() - after go live */ }
                    { /* this.renderReorderButton() - after go live */ }
                { /* </div> */ }
                { this.renderOrderComments() }
                { this.renderAdditionalInfoTable() }
                { this.renderExternalOrderInformation() }
                { this.renderCustomOrderItemsTable() }
                { this.renderCustomOrderTotals() }
            </div>
        );
    }

    renderPrintOrder() {
        const { order: { id } } = this.props;

        return (
            <Link
              block="MyAccountOrder"
              elem="SubscribeToStatus"
              to={ appendWithStoreCode(`${ACCOUNT_ORDER_PRINT_URL}/${id}`) }
              isOpenInNewTab
            >
                <PrinterIcon />
                { __('Print Order') }
            </Link>
        );
    }

    renderContent() {
        const { isLoading, order: { items } } = this.props;

        if (isLoading) {
            return <Loader isLoading={ isLoading } />;
        }

        if (!items) {
            return null;
        }

        return (
            <>
                { this.renderActions() }
                { this.renderOrderInformation() }
            </>
        );
    }
}

export default MyAccountOrder;
