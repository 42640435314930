/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ConfigQuery as SourceConfigQuery } from 'SourceQuery/Config.query';

/** @namespace Eduksiazka/Query/Config/Query */
export class ConfigQuery extends SourceConfigQuery {
    _getStoreConfigFields() {
        return [
            ...super._getStoreConfigFields(),
            'satisfly_productavailabilitylist_general_enable',
            'catalog_frontend_default_sort_by',
            'grid_per_page',
            'grid_per_page_values',
            'list_per_page',
            'list_per_page_values',
            'satisfly_askaboutproduct_general_enable',
            'satisfly_askaboutproduct_general_checkbox_message',
            'satisfly_hiddeninformationattributes_general_enable',
            'satisfly_hiddeninformationattributes_general_attribute_to_hide'
        ];
    }
}

export default new ConfigQuery();
