/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { getStatusLabel, getStatusMod } from 'Util/Orders/Orders';

import './MyAccountOrderStatus.style';

/** @namespace Eduksiazka/Component/MyAccountOrderStatus/Component */
export class MyAccountOrderStatus extends PureComponent {
    static propTypes = {
        status: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['order', 'invoice'])
    };

    static defaultProps = {
        type: 'order'
    };

    render() {
        const {
            status, type
        } = this.props;

        return (
            <span
              block="MyAccountOrderStatus"
              elem="Status"
              mods={ { type, status: status ? getStatusMod(status, type) : '' } }
            >
                { status ? getStatusLabel(status, type) : '' }
            </span>
        );
    }
}

export default MyAccountOrderStatus;
