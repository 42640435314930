/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import { ReactComponent as CloseIconSvg } from 'src/assets/icons/x-black.svg';

import {
    CloseIcon as SourceCloseIcon
} from 'SourceComponent/CloseIcon/CloseIcon.component';

import './CloseIcon.style';

/** @namespace Eduksiazka/Component/CloseIcon/Component */
export class CloseIcon extends SourceCloseIcon {
    render() {
        return (
            <div block="CloseIcon">
                <CloseIconSvg />
            </div>
        );
    }
}

export default CloseIcon;
