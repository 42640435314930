/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export * from 'SourceRoute/MyAccount/MyAccount.config';

export const ACCOUNT_MENU_PAGE_URL = 'account-menu';
export const MENU = 'menu';
export const CUSTOMER = 'customer';
export const WISHLIST = 'wishlist';
export const SALES = 'sales';
export const ACCOUNT_INVOICE_URL = '/sales/invoice/view/invoice_id';
export const ACCOUNT_INVOICE_HISTORY_URL = '/sales/invoice/history';
export const ACCOUNT_INVOICE_PRINT_URL = '/sales/invoice/print/invoice_id';
