/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { getInitialState } from 'SourceStore/Popup/Popup.reducer';
import {
    HIDE_ACTIVE_OVERLAY, HIDE_ACTIVE_POPUP,
    TOGGLE_OVERLAY
} from 'Store/Overlay/Overlay.action';
import { SHOW_POPUP } from 'Store/Popup/Popup.action';

export * from 'SourceStore/Popup/Popup.reducer';

/** @namespace Eduksiazka/Store/Popup/Reducer/PopupReducer */
export const PopupReducer = (
    state = getInitialState(),
    action
) => {
    const { payload, type } = action;

    switch (type) {
    case TOGGLE_OVERLAY:
    case SHOW_POPUP:
        return { ...state, popupPayload: payload };
    case HIDE_ACTIVE_OVERLAY:
        return { ...state, popupPayload: {} };
    case HIDE_ACTIVE_POPUP:
        return { ...state, shouldPopupClose: payload };
    default:
        return state;
    }
};

export default PopupReducer;
