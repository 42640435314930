/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { AddIcon as SourceAddIcon } from 'SourceComponent/AddIcon/AddIcon.component';

import './AddIcon.override.style';

/** @namespace Eduksiazka/Component/AddIcon/Component */
export class AddIcon extends SourceAddIcon {
    render() {
        const { isPrimary } = this.props;

        return (
            <svg
              block="AddIcon"
              mods={ { isPrimary } }
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M11.4256 8.62903H20V11.4113H11.4256V20H8.53278V11.4113H0V8.62903H8.53278V0H11.4256V8.62903Z" />
            </svg>
        );
    }
}

export default AddIcon;
