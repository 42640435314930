/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const GET_INVOICE_LIST = 'GET_INVOICE_LIST';
export const SET_INVOICE_LOADING_STATUS = 'SET_INVOICE_LOADING_STATUS';

/** @namespace Eduksiazka/Store/Invoice/Action/getInvoiceList */
export const getInvoiceList = (invoiceList, status) => ({
    type: GET_INVOICE_LIST,
    invoiceList,
    status
});

/** @namespace Eduksiazka/Store/Invoice/Action/setLoadingStatus */
export const setLoadingStatus = (status) => ({
    type: SET_INVOICE_LOADING_STATUS,
    status
});
