/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export * from 'SourceUtil/Product/Product';

/** @namespace Eduksiazka/Util/Product/getSpecialProductType */
export const getSpecialProductType = (product) => {
    let specialProductType = null;

    const { attributes } = product;

    if (!attributes) {
        return null;
    }

    const {
        attributes: {
            special_product_types = null
        } = {}
    } = product;

    if (special_product_types) {
        const { attribute_options, attribute_value } = special_product_types;

        specialProductType = attribute_options[attribute_value]?.label || null;
    }

    return specialProductType;
};
