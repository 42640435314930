/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';

import {
    MenuItem as SourceMenuItem
} from 'SourceComponent/MenuItem/MenuItem.component';

import './MenuItem.override.style';

/** @namespace Eduksiazka/Component/MenuItem/Component */
export class MenuItem extends SourceMenuItem {
    renderPlusMinusIcon() {
        const { itemMods: { isExpanded } } = this.props;

        if (isExpanded) {
            return <ChevronUpIcon />;
        }

        return <ChevronDownIcon />;
    }
}

export default MenuItem;
