/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    RESET_CHECKOUT_FIELDS, UPDATE_CLIENT_COMMENT,
    UPDATE_IS_SAME_AS_BILLING,
    UPDATE_OWN_ORDER_NUMBER, UPDATE_PAYMENT_METHOD_CODE,
    UPDATE_SELECTED_ADDRESS_ID,
    UPDATE_SHIPPING_METHOD_CODE
} from './CheckoutFields.action';

export const initialState = {
    shippingMethodCode: '',
    paymentMethodCode: '',
    clientComment: '',
    ownOrderNumber: '',
    isSameAsBilling: false,
    selectedAddressId: null
};

/** @namespace Eduksiazka/Store/CheckoutFields/Reducer/CheckoutFieldsReducer */
// eslint-disable-next-line no-unused-vars
export const CheckoutFieldsReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_SHIPPING_METHOD_CODE:
        const { shippingMethodCode } = action;

        return {
            ...state,
            shippingMethodCode
        };
    case UPDATE_SELECTED_ADDRESS_ID:
        const { selectedAddressId } = action;

        return {
            ...state,
            selectedAddressId
        };

    case UPDATE_PAYMENT_METHOD_CODE:
        const { paymentMethodCode } = action;

        return {
            ...state,
            paymentMethodCode
        };
    case UPDATE_CLIENT_COMMENT:
        const { clientComment } = action;

        return {
            ...state,
            clientComment
        };
    case UPDATE_OWN_ORDER_NUMBER:
        const { ownOrderNumber } = action;

        return {
            ...state,
            ownOrderNumber
        };

    case UPDATE_IS_SAME_AS_BILLING:
        const { isSameAsBilling } = action;

        return {
            ...state,
            isSameAsBilling
        };

    case RESET_CHECKOUT_FIELDS:
        return initialState;

    default:
        return state;
    }
};

export default CheckoutFieldsReducer;
