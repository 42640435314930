/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { formatPrice } from 'Util/Price';

export * from 'SourceUtil/Product/Extract';

export const DEFAULT_MAX_PRODUCTS = 5000;

/** @namespace Eduksiazka/Util/Product/Extract/getPrice */
export const getPrice = (
    priceRange,
    dynamicPrice = false,
    adjustedPrice = {},
    type = PRODUCT_TYPE.simple,
    options = []
) => {
    const priceAcc = type === PRODUCT_TYPE.bundle
        ? 'default_final_price'
        : 'regular_price';
    const priceExcTaxAcc = type === PRODUCT_TYPE.bundle || type === PRODUCT_TYPE.configurable
        ? 'default_final_price_excl_tax'
        : 'regular_price_excl_tax';
    const accessRange = type === PRODUCT_TYPE.virtual || type === PRODUCT_TYPE.downloadable
        ? 'maximum_price'
        : 'minimum_price';

    const {
        [accessRange]: {
            [priceAcc]: { currency = 'USD', value: basePrice = 0 } = {},
            [priceExcTaxAcc]: { value: basePriceExclTax = 0 } = {},
            discount: {
                percent_off: percentOffRef = 0,
                amount_off: amountOff = 0
            } = {}
        } = {},
        minimum_price: {
            regular_price: minRegularPrice = {},
            final_price: minFinalPrice = {},
            final_price_excl_tax: minFinalPriceExclTax = {}
        } = {},
        maximum_price: {
            regular_price: maxRegularPrice = {},
            final_price: maxFinalPrice = {},
            final_price_excl_tax: maxFinalPriceExclTax = {}
        } = {}
    } = priceRange || {};

    // Fixes decimal misplacement for discount
    // eslint-disable-next-line no-magic-numbers
    const percentOffCalc = (amountOff / basePrice) * 100;
    // eslint-disable-next-line no-magic-numbers
    const percentOff = Math.round(percentOffCalc * 10) / 10 === percentOffRef
        ? percentOffCalc
        : percentOffRef;

    // eslint-disable-next-line no-magic-numbers
    const discountValue = (1 - percentOff / 100);
    // eslint-disable-next-line no-magic-numbers
    const discountValueRevert = discountValue === 0 ? 1 : discountValue;

    const basePriceExclDiscount = priceAcc === 'default_final_price'
        ? basePrice / discountValueRevert
        : basePrice;
    const basePriceExclDiscountExclTax = priceAcc === 'default_final_price'
        ? basePriceExclTax / discountValueRevert
        : basePriceExclTax;

    const priceValue = { value: dynamicPrice ? 0 : basePriceExclDiscount * discountValue, currency };
    const priceValueExclTax = { value: dynamicPrice ? 0 : basePriceExclDiscountExclTax * discountValue, currency };
    const priceValueExclDiscount = { value: dynamicPrice ? 0 : basePriceExclDiscount, currency };
    const priceValueExclDiscountExclTax = { value: dynamicPrice ? 0 : basePriceExclDiscountExclTax, currency };

    // Adds adjusted price
    Object.keys(adjustedPrice || {}).forEach((key) => {
        const { [key]: group } = adjustedPrice;
        const {
            inclTax = 0,
            exclTax = 0,
            requiresDiscountCalculations = true,
            hasDiscountCalculated = false
        } = group;

        if (requiresDiscountCalculations) {
            if (hasDiscountCalculated) {
                priceValue.value += inclTax;
                priceValueExclTax.value += exclTax;
                priceValueExclDiscount.value += inclTax / discountValueRevert;
                priceValueExclDiscountExclTax.value += exclTax / discountValueRevert;
            } else {
                priceValue.value += inclTax * discountValue;
                priceValueExclTax.value += exclTax * discountValue;
                priceValueExclDiscount.value += inclTax;
                priceValueExclDiscountExclTax.value += exclTax;
            }
        } else {
            priceValue.value += inclTax;
            priceValueExclTax.value += exclTax;
            priceValueExclDiscount.value += inclTax;
            priceValueExclDiscountExclTax.value += exclTax;
        }
    });

    // Adds formatted price option
    priceValue.valueFormatted = formatPrice(priceValue.value, currency);
    priceValueExclTax.valueFormatted = formatPrice(priceValueExclTax.value, currency);
    priceValueExclDiscount.valueFormatted = formatPrice(priceValueExclDiscount.value, currency);
    priceValueExclDiscountExclTax.valueFormatted = formatPrice(priceValueExclDiscountExclTax.value, currency);

    const configuration = {
        containsOptions: options && !!options.length,
        containsOptionsWithPrice: false,
        containsRequiredOptions: false,
        containsRequiredOptionsWithPrice: false
    };

    if (options) {
        configuration.containsOptionsWithPrice = !!options.find(
            ({ value = [] }) => Array.isArray(value) && value.find(({ price }) => price)
        );
        const requiredOptions = options.filter(({ required }) => required);

        configuration.containsRequiredOptions = !!requiredOptions.length;

        if (requiredOptions.length) {
            configuration.containsRequiredOptionsWithPrice = !!requiredOptions.find(
                ({ value = [] }) => Array.isArray(value) && value.find(({ price }) => price)
            );
        }
    }

    // eslint-disable-next-line no-magic-numbers
    const roundedPercentDiscount = Math.round(percentOff * 10) / 10;

    return {
        price: {
            finalPrice: priceValue,
            finalPriceExclTax: priceValueExclTax,
            originalPrice: priceValueExclDiscount,
            originalPriceExclTax: priceValueExclDiscountExclTax,
            discount: {
                amountOff,
                percentOff: roundedPercentDiscount
            }
        },
        originalPrice: {
            minRegularPrice: {
                ...minRegularPrice,
                valueFormatted: formatPrice(minRegularPrice.value || 0, currency)
            },
            minFinalPrice: {
                ...minFinalPrice,
                valueFormatted: formatPrice(minFinalPrice.value || 0, currency)
            },
            minFinalPriceExclTax: {
                ...minFinalPriceExclTax,
                valueFormatted: formatPrice(minFinalPriceExclTax.value || 0, currency)
            },
            maxRegularPrice: {
                ...maxRegularPrice,
                valueFormatted: formatPrice(maxRegularPrice.value || 0, currency)
            },
            maxFinalPrice: {
                ...maxFinalPrice,
                valueFormatted: formatPrice(maxFinalPrice.value || 0, currency)
            },
            maxFinalPriceExclTax: {
                ...maxFinalPriceExclTax,
                valueFormatted: formatPrice(maxFinalPriceExclTax.value || 0, currency)
            }
        },
        configuration
    };
};
/** @namespace Eduksiazka/Util/Product/Extract/getMaxQuantity */
export const getMaxQuantity = () => DEFAULT_MAX_PRODUCTS;
