/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy, Suspense } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { LEFT } from 'Component/ChevronIcon/ChevronIcon.config';
import ContentWrapper from 'Component/ContentWrapper';
import Link from 'Component/Link';
import Loader from 'Component/Loader/Loader.component';
import MyAccountTabList from 'Component/MyAccountTabList';
import { ACCOUNT_ORDER_URL } from 'Route/MyAccount/MyAccount.config';
import NoMatch from 'Route/NoMatch';
import { MyAccount as SourceMyAccount } from 'SourceRoute/MyAccount/MyAccount.component';
import {
    MY_INVOICE,
    MY_INVOICES,
    MY_ORDER,
    MY_ORDERS,
    PRODUCT_AVAILABILITY_LIST
} from 'Type/Account.type';
import { isSignedIn } from 'Util/Auth';

import { FAST_ORDER } from '../../type/Account.type';
import { ACCOUNT_INVOICE_HISTORY_URL, ACCOUNT_INVOICE_URL } from './MyAccount.config';

import './MyAccount.override.style';

export const FastOrder = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-availability" */
    'Component/FastOrder'
));

export const ProductAvailabilityList = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-availability" */
    'Component/ProductAvailabilityList'
));

export const MyAccountInvoices = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-invoices" */
    'Component/MyAccountInvoices'
));

export const MyAccountInvoice = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-invoice" */
    'Component/MyAccountInvoice'
));

/** @namespace Eduksiazka/Route/MyAccount/Component */
export class MyAccount extends SourceMyAccount {
    renderMap = {
        ...this.renderMap,
        [PRODUCT_AVAILABILITY_LIST]: ProductAvailabilityList,
        [FAST_ORDER]: FastOrder,
        [MY_INVOICES]: MyAccountInvoices,
        [MY_INVOICE]: MyAccountInvoice
    };

    renderBackLink() {
        const {
            activeTab,
            location: { pathname }

        } = this.props;

        const backLinkTabs = [MY_INVOICES];

        if (backLinkTabs.includes(activeTab) && pathname.includes(ACCOUNT_INVOICE_URL)) {
            return (
                <div
                  block="MyAccount"
                  elem="BackBtn"
                >
                    <Link to={ ACCOUNT_INVOICE_HISTORY_URL }>
                        <ChevronIcon direction={ LEFT } />
                        { __('Back to invoice list') }
                    </Link>
                </div>
            );
        }

        return null;
    }

    getTabContent() {
        const { activeTab, location: { pathname } } = this.props;

        if (activeTab === MY_ORDERS && pathname.includes(ACCOUNT_ORDER_URL)) {
            return this.renderMap[MY_ORDER];
        }

        if (activeTab === MY_INVOICES && pathname.includes(ACCOUNT_INVOICE_URL)) {
            return this.renderMap[MY_INVOICE];
        }

        return this.renderMap[activeTab];
    }

    renderContent() {
        const {
            activeTab,
            tabMap,
            changeActiveTab,
            onSignOut,
            isEditingActive,
            match,
            changeTabName,
            tabName,
            setTabSubheading,
            isTabEnabled,
            location: { pathname }
        } = this.props;

        if (!isSignedIn()) {
            return this.renderLoginOverlay();
        }

        if (!isTabEnabled(activeTab)) {
            return <NoMatch />;
        }

        const TabContent = this.getTabContent();
        let { title } = tabMap[activeTab];

        if (activeTab === MY_INVOICES && !pathname.includes(ACCOUNT_INVOICE_URL)) {
            title = __('My invoices');
        }

        return (
            <ContentWrapper
              label={ __('My Account page') }
              wrapperMix={ { block: 'MyAccount', elem: 'Wrapper' } }
            >
                <MyAccountTabList
                  tabMap={ tabMap }
                  activeTab={ activeTab }
                  changeActiveTab={ changeActiveTab }
                  onSignOut={ onSignOut }
                />
                <div
                  block="MyAccount"
                  elem="TabContent"
                  mods={ { activeTab } }
                >
                    { this.renderBackLink() }
                    <h2 block="MyAccount" elem="Heading">
                        { title || tabName }
                        { this.renderSubHeading() }
                    </h2>
                    <Suspense fallback={ <Loader /> }>
                        <TabContent
                          isEditingActive={ isEditingActive }
                          match={ match }
                          changeTabName={ changeTabName }
                          tabMap={ tabMap }
                          setTabSubheading={ setTabSubheading }
                        />
                    </Suspense>
                </div>
            </ContentWrapper>
        );
    }
}

export default MyAccount;
