/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export * from 'SourceComponent/Router/Router.config';

export const PRODUCT_AVAILABILITY_LIST = 'PRODUCT_AVAILABILITY_LIST';
export const MY_ACCOUNT_MENU_PAGE = 'MY_ACCOUNT_MENU_PAGE';
export const ASK_ABOUT_POPUP = 'ASK_ABOUT_POPUP';

export const FAST_ADD_POPUP = 'FAST_ADD_POPUP';
export const MY_ACCOUNT_INVOICE = 'MY_ACCOUNT_INVOICE';
export const MY_ACCOUNT_INVOICES = 'MY_ACCOUNT_INVOICES';
export const SALABLE_QTY_ERROR_POPUP = 'SALABLE_QTY_ERROR_POPUP';
