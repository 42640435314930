/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import currencyMap from './Price.config';

export * from 'SourceUtil/Price/Price';

/** @namespace Eduksiazka/Util/Price/formatCurrency */
export const formatCurrency = (currency = 'PLN') => currencyMap[currency];

/** @namespace Eduksiazka/Util/Price/formatPriceValue */
export const formatPriceValue = (price) => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;

    return new Intl.NumberFormat(language, { minimumFractionDigits: 2 }).format(price);
};

/** @namespace Eduksiazka/Util/Price/formatPrice */
export const formatPrice = (price, currentCurrency) => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;
    const currency = currentCurrency || 'PLN';

    return new Intl.NumberFormat(language, { style: 'currency', currency }).format(price);
};
