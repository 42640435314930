/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import MenuQuery from 'Query/Menu.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuContainer as SourceMenuContainer
} from 'SourceComponent/Menu/Menu.container';
import MenuHelper from 'Util/Menu';

/** @namespace Eduksiazka/Component/Menu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isSignIn: state.MyAccountReducer.isSignedIn
});

/** @namespace Eduksiazka/Component/Menu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Eduksiazka/Component/Menu/Container */
export class MenuContainer extends SourceMenuContainer {
    containerProps() {
        const {
            device,
            compareTotals,
            isSignIn
        } = this.props;

        const {
            activeMenuItemsStack,
            menu,
            subMenu
        } = this.state;

        return {
            activeMenuItemsStack,
            menu,
            device,
            compareTotals,
            subMenu,
            isSignIn
        };
    }

    _getMenuOptions() {
        const { header_content: { header_menu } = {} } = window.contentConfiguration;

        return {
            identifier: header_menu || 'new-main-menu-edu'
        };
    }

    _getMenu() {
        this.fetchData(
            [MenuQuery.getQuery(this._getMenuOptions())],
            ({ menu }) => this.setState({
                menu: MenuHelper.reduce(menu)
            })
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
