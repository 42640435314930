/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ChevronIcon as SourceChevronIcon } from 'SourceComponent/ChevronIcon/ChevronIcon.component';

import './ChevronIcon.override.style';

/** @namespace Eduksiazka/Component/ChevronIcon/Component */
export class ChevronIcon extends SourceChevronIcon {
    render() {
        const { direction } = this.props;

        return (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  block="ChevronIcon"
                  mods={ { direction } }
                >
                    <path d="M9 18L15 12L9 6" stroke="#4E5362" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
        );
    }
}

export default ChevronIcon;
