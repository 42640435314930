/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ExpandableContent as SourceExpandableContent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';

/** @namespace Eduksiazka/Component/ExpandableContent/Component */
export class ExpandableContent extends SourceExpandableContent {
    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { isArrow, device: { isMobile }, activeOnMobileAndDesktop } = this.props;

        if (!isMobile && !activeOnMobileAndDesktop) {
            return null;
        }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }

    renderTogglePlusMinus() {
        const { isContentExpanded } = this.state;

        return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const {
            heading, mix, activeOnMobileAndDesktop, arrowIconBeforeHeading
        } = this.props;

        return (
            <div
              role="button"
              tabIndex={ 0 }
              block="ExpandableContent"
              elem="Button"
              mods={ { isContentExpanded, activeOnMobileAndDesktop } }
              mix={ { ...mix, elem: 'ExpandableContentButton' } }
              onClick={ this.toggleExpand }
              onKeyDown={ this.toggleExpand }
            >
                { arrowIconBeforeHeading && this.renderButtonIcon() }
                <div
                  block="ExpandableContent"
                  elem="Heading"
                  mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                >
                    { typeof heading === 'string' ? (
                        <TextPlaceholder content={ heading } length="medium" />
                    ) : (
                        heading
                    ) }
                </div>
                { !arrowIconBeforeHeading && this.renderButtonIcon() }
            </div>
        );
    }
}

export default ExpandableContent;
