/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace Eduksiazka/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    _getTopBarNotification() {
        return new Field('top_bar_notification')
            .addFieldList([
                'content',
                'title',
                'identifier'
            ]);
    }

    _getConsultantFields() {
        return new Field('consultant')
            .addFieldList([
                'name',
                'phone',
                'email'
            ]);
    }

    _getCustomerFields() {
        return [
            ...super._getCustomerFields(),
            this._getTopBarNotification(),
            this._getConsultantFields(),
            ...this._getDashboardFields()
        ];
    }

    _getDashboardFields() {
        return [
            'credit_limit',
            'logistic_minimum',
            'balance',
            'overdue_payments'
        ];
    }

    _getAddressFields() {
        return [
            'id',
            'customer_id',
            'country_id',
            'street',
            'telephone',
            'company',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'vat_id',
            this._getRegionField()
        ];
    }

    getCustomerStoreUrl(email = '') {
        return new Field('getCustomerStoreUrl').addArgument('email', 'String!', email);
    }
}

export default new MyAccountQuery();
