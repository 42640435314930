/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const RESET_CHECKOUT_FIELDS = 'RESET_CHECKOUT_FIELDS';
export const UPDATE_SHIPPING_METHOD_CODE = 'UPDATE_SHIPPING_METHOD_CODE';
export const UPDATE_PAYMENT_METHOD_CODE = 'UPDATE_PAYMENT_METHOD_CODE';
export const UPDATE_CLIENT_COMMENT = 'UPDATE_CLIENT_COMMENT';
export const UPDATE_OWN_ORDER_NUMBER = 'UPDATE_OWN_ORDER_NUMBER';
export const UPDATE_IS_SAME_AS_BILLING = 'UPDATE_IS_SAME_AS_BILLING';
export const UPDATE_SELECTED_ADDRESS_ID = 'UPDATE_SELECTED_ADDRESS_ID';

/** @namespace Eduksiazka/Store/CheckoutFields/Action/updateSelectedAddressId */
export const updateSelectedAddressId = (selectedAddressId) => ({
    type: UPDATE_SELECTED_ADDRESS_ID,
    selectedAddressId
});
/** @namespace Eduksiazka/Store/CheckoutFields/Action/updateShippingMethodCode */
export const updateShippingMethodCode = (shippingMethodCode) => ({
    type: UPDATE_SHIPPING_METHOD_CODE,
    shippingMethodCode
});

/** @namespace Eduksiazka/Store/CheckoutFields/Action/updatePaymentMethodCode */
export const updatePaymentMethodCode = (paymentMethodCode) => ({
    type: UPDATE_PAYMENT_METHOD_CODE,
    paymentMethodCode
});

/** @namespace Eduksiazka/Store/CheckoutFields/Action/resetCheckoutFields */
export const resetCheckoutFields = () => ({
    type: RESET_CHECKOUT_FIELDS
});

/** @namespace Eduksiazka/Store/CheckoutFields/Action/updateClientComment */
export const updateClientComment = (clientComment) => ({
    type: UPDATE_CLIENT_COMMENT,
    clientComment
});

/** @namespace Eduksiazka/Store/CheckoutFields/Action/updateOwnOrderNumber */
export const updateOwnOrderNumber = (ownOrderNumber) => ({
    type: UPDATE_OWN_ORDER_NUMBER,
    ownOrderNumber
});
/** @namespace Eduksiazka/Store/CheckoutFields/Action/updateIsSameAsBilling */
export const updateIsSameAsBilling = (isSameAsBilling) => ({
    type: UPDATE_IS_SAME_AS_BILLING,
    isSameAsBilling
});
