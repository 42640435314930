/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import WishlistQuery from 'Query/Wishlist.query';
import { WishlistDispatcher as SourceWishlistDispatcher } from 'SourceStore/Wishlist/Wishlist.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    removeItemFromWishlist,
    updateIsLoading
} from 'Store/Wishlist/Wishlist.action';
import { isSignedIn } from 'Util/Auth';
import { fetchMutation, getErrorMessage } from 'Util/Request';

export * from 'SourceStore/Wishlist/Wishlist.dispatcher';

/**
 * Product Wishlist Dispatcher
 * @class WishlistDispatcher
 * @namespace Eduksiazka/Store/Wishlist/Dispatcher */
export class WishlistDispatcher extends SourceWishlistDispatcher {
    async addItemToWishlist(dispatch, options) {
        if (!isSignedIn()) {
            return;
        }

        try {
            const { items = [], wishlistId = '' } = options;

            dispatch(updateIsLoading(true));
            const {
                addProductsToWishlist: { user_errors }
            } = await fetchMutation(WishlistQuery.addProductsToWishlist(wishlistId, items.map((item) => ({ ...item, quantity: 1 }))));

            if (user_errors.length > 0) {
                user_errors.map(({ message }) => dispatch(
                    showNotification('error', __('We can`t add the item to Wishlist right now: %s', message).toString())
                ));
            } else {
                dispatch(showNotification('success', __('Product added to clipboard!')));
                await this._syncWishlistWithBE(dispatch);
            }
        } catch {
            dispatch(showNotification('error', __('Error updating wish list!')));
        } finally {
            dispatch(updateIsLoading(false));
        }
    }

    async removeItemFromWishlist(dispatch, { item_id, noMessages }) {
        if (!item_id || !isSignedIn()) {
            return Promise.reject();
        }

        dispatch(updateIsLoading(true));

        try {
            await fetchMutation(WishlistQuery.getRemoveProductFromWishlistMutation(item_id));
        } catch (e) {
            if (!noMessages) {
                dispatch(showNotification('error', __('Error updating wish list!')));
            }

            return Promise.reject();
        }

        dispatch(removeItemFromWishlist(item_id));

        if (!noMessages) {
            dispatch(showNotification('success', __('Product has been removed from clipboard!')));
        }

        return Promise.resolve();
    }

    // TODO: Need to make it in one request
    removeItemsFromWishlist(dispatch, itemIdMap) {
        if (!itemIdMap.length || !isSignedIn()) {
            return Promise.reject();
        }

        return itemIdMap.map((id) => (
            fetchMutation(WishlistQuery.getRemoveProductFromWishlistMutation(id)).then(
                /** @namespace Eduksiazka/Store/Wishlist/Dispatcher/WishlistDispatcher/removeItemsFromWishlist/itemIdMap/map/fetchMutation/then */
                () => {
                    dispatch(removeItemFromWishlist(id));
                    dispatch(showNotification('success', __('Product has been removed from clipboard!')));
                },
                /** @namespace Eduksiazka/Store/Wishlist/Dispatcher/WishlistDispatcher/removeItemsFromWishlist/itemIdMap/map/fetchMutation/then/catch */
                (error) => {
                    dispatch(showNotification('error', getErrorMessage(error, __('Error updating wishlist!'))));
                }
            )
        ));
    }
}

export default new WishlistDispatcher();
