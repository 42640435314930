/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import CreateAccountContactInfo from 'Component/CreateAccountContactInfo/CreateAccountContactInfo.component';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import { MyAccountForgotPassword as SourceMyAccountForgotPassword } from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.component';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import './MyAccountForgotPassword.style.scss';

/** @namespace Eduksiazka/Component/MyAccountForgotPassword/Component */
export class MyAccountForgotPassword extends SourceMyAccountForgotPassword {
    renderCreateAccountLabel() {
        const { isCheckout } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <section aria-labelledby="create-account-label">
                <CreateAccountContactInfo id="create-account-label" />
            </section>
        );
    }

    renderForgotPasswordForm() {
        const { onForgotPasswordSuccess, onFormError } = this.props;

        return (
            <Form
              key="forgot-password"
              onSubmit={ onForgotPasswordSuccess }
              onError={ onFormError }
            >
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Email') }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      class: 'ForgotPassword-Input_type_email',
                      placeholder: __('Your email address'),
                      autocomplete: 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  addRequiredTag
                />
                <div block="MyAccountOverlay" elem="Buttons">
                    <button
                      block="Button"
                      type="submit"
                      mix={ { block: 'MyAccountOverlay', elem: 'ResetPassword' } }
                    >
                        { __('Reset password') }
                    </button>
                </div>
            </Form>
        );
    }
}

export default MyAccountForgotPassword;
