/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import CreateAccountContactInfo from 'Component/CreateAccountContactInfo/CreateAccountContactInfo.component';
import { MyAccountSignIn as SourceMyAccountSignIn } from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';

import './MyAccountSignIn.override.style';

/** @namespace Eduksiazka/Component/MyAccountSignIn/Component */
export class MyAccountSignIn extends SourceMyAccountSignIn {
    renderAdditionalField() {
        const {
            isCheckout,
            state
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <article block="MyAccountOverlay" elem="Additional" mods={ { state } }>
                <section>
                    <CreateAccountContactInfo id="forgot-password-label" />
                </section>
            </article>
        );
    }
}

export default MyAccountSignIn;
