/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { MyAccountOrderInformation as SourceMyAccountOrderInformation } from 'SourceComponent/MyAccountOrderInformation/MyAccountOrderInformation.component';

import './MyAccountOrderInformation.override.style';

/** @namespace Eduksiazka/Component/MyAccountOrderInformation/Component */
export class MyAccountOrderInformation extends SourceMyAccountOrderInformation {
    renderBillingAddress() {
        const { order: { address, cardname, name } = {}, isInvoice } = this.props;
        const splittedBillingAddress = address.split(/\r/);

        return (
             <div
               block="MyAccountOrderInformation"
               elem="Column"
               mods={ { type: 'billingAddress' } }
             >
                 <div
                   block="MyAccountOrderInformation"
                   elem="ColumnTitle"
                 >
                     <strong>
                         <span>{ __('Invoice details') }</span>
                     </strong>
                 </div>
                 <div
                   block="MyAccountOrderInformation"
                   elem="PaymentMethod"
                 >
                    <span>{ isInvoice ? name : cardname }</span>
                    <span>{ splittedBillingAddress[0] || '' }</span>
                    <span>{ splittedBillingAddress[1] || '' }</span>
                 </div>
             </div>
        );
    }

    renderShippingAddress() {
        const { order: { address2, nazwaadresudostawy, shiptocode } = {} } = this.props;
        const splittedShippingAddress = address2.split(/\r/);

        return (
             <div
               block="MyAccountOrderInformation"
               elem="Column"
               mods={ { type: 'shippingAddress' } }
             >
                 <div
                   block="MyAccountOrderInformation"
                   elem="ColumnTitle"
                 >
                     <strong>
                         <span>{ __('Shipping Address') }</span>
                     </strong>
                 </div>
                 <div
                   block="MyAccountOrderInformation"
                   elem="PaymentMethod"
                 >
                    <span>{ nazwaadresudostawy ?? shiptocode }</span>
                    <span>{ splittedShippingAddress[0] || '' }</span>
                    <span>{ splittedShippingAddress[1] || '' }</span>
                 </div>
             </div>
        );
    }

    renderShippingMethod() {
        const { order: { formadostawy } = {} } = this.props;

        if (!formadostawy) {
            return null;
        }

        return (
             <div
               block="MyAccountOrderInformation"
               elem="Column"
             >
                 <div
                   block="MyAccountOrderInformation"
                   elem="ColumnTitle"
                 >
                     <strong>
                         <span>{ __('Shipping Method') }</span>
                     </strong>
                 </div>
                 <span
                   block="MyAccountOrderInformation"
                   elem="ShippingMethod"
                 >
                     { formadostawy }
                 </span>
             </div>
        );
    }

    renderPaymentMethods() {
        const { order: { formaplatnosci, docpayment } = {}, isInvoice } = this.props;

        return (
             <div
               block="MyAccountOrderInformation"
               elem="Column"
             >
                 <div
                   block="MyAccountOrderInformation"
                   elem="ColumnTitle"
                 >
                     <strong>
                         <span>{ __('Payment Method') }</span>
                     </strong>
                 </div>
                 <div
                   block="MyAccountOrderInformation"
                   elem="PaymentMethod"
                 >
                     <span>{ isInvoice ? docpayment : formaplatnosci }</span>
                 </div>
             </div>
        );
    }

    renderComments() {
        const { order: { comments } = {} } = this.props;

        return (
             <div
               block="MyAccountOrderInformation"
               elem="Column"
             >
                 <div
                   block="MyAccountOrderInformation"
                   elem="ColumnTitle"
                 >
                     <strong>
                         <span>{ __('Comments') }</span>
                     </strong>
                 </div>
                 <div
                   block="MyAccountOrderInformation"
                   elem="PaymentMethod"
                 >
                     <span>{ comments }</span>
                 </div>
             </div>
        );
    }

    renderOrderNr() {
        const { order: { docnum } } = this.props;

        return (
             <div
               block="MyAccountOrderInformation"
               elem="Column"
             >
                 <div
                   block="MyAccountOrderInformation"
                   elem="ColumnTitle"
                 >
                     <strong>
                         <span>{ __('Own order number') }</span>
                     </strong>
                 </div>
                 <div
                   block="MyAccountOrderInformation"
                   elem="PaymentMethod"
                 >
                     <span>{ docnum }</span>
                 </div>
             </div>
        );
    }

    renderContent() {
        return (
            <>
                 <div
                   block="MyAccountOrderInformation"
                   elem="Title"
                 >
                     { __('Order Information') }
                 </div>
                 <div
                   block="MyAccountOrderInformation"
                   elem="Information"
                 >
                     { this.renderBillingAddress() }
                     { this.renderShippingAddress() }
                     { this.renderShippingMethod() }
                     { this.renderPaymentMethods() }
                     { this.renderComments() }
                     { this.renderOrderNr() }
                 </div>
            </>
        );
    }

    render() {
        return (
            <div
              block="MyAccountOrderInformation"
              elem="Wrapper"
            >
                { this.renderContent() }
            </div>
        );
    }
}

export default MyAccountOrderInformation;
