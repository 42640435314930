/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { MinusIcon as SourceMinusIcon } from 'SourceComponent/MinusIcon/MinusIcon.component';

import './MinusIcon.override.style';

/** @namespace Eduksiazka/Component/MinusIcon/Component */
export class MinusIcon extends SourceMinusIcon {
    render() {
        const { isPrimary } = this.props;

        return (
            <svg
              block="MinusIcon"
              mods={ { isPrimary } }
              width="20"
              height="2"
              viewBox="0 0 20 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M20 2V0H0V2H20Z" />
            </svg>
        );
    }
}

export default MinusIcon;
