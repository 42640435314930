/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace Eduksiazka/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    _getCustomPrice() {
        return new Field('custom_price')
            .addFieldList([
                'value',
                'discount_type'
            ]);
    }

    _getCartProductInterfaceFields() {
        return [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'url',
            'salable_qty',
            this._getCustomPrice(),
            this._getPriceRangeField(),
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getCartConfigurableProductFragment(),
            this._getAttributesField(false, true),
            this._getProductLinksField()
        ];
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        return [
            'tax_rate',
            ...super._getProductInterfaceFields(isVariant, isForLinkedProducts, isForWishlist),
            this._getCustomPrice(),
            this._getFbtProducts()
        ];
    }

    _getCategories() {
        return new Field('categories_matched').addFieldList(['name', 'url']);
    }

    _getProductFields() {
        const {
            categories, requireInfo, isSingleProduct, notRequireInfo
        } = this.options;

        // do not request total count for PDP
        if (isSingleProduct || notRequireInfo) {
            const items = [
                this._getItemsField()
            ];

            if (categories) {
                return [...items, this._getCategories()];
            }

            return items;
        }

        // for filters only request
        if (requireInfo) {
            return [
                this._getSortField(),
                this._getAggregationsField()
            ];
        }

        return [
            'total_count',
            this._getItemsField(),
            this._getPageInfoField()
        ];
    }

    _getFbtProducts() {
        return new Field('fbt_products')
            .addFieldList(['sku', new Field('stock_item').addField('in_stock')]);
    }
}

export default new ProductListQuery();
