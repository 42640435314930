/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { getFormattedDate } from 'SourceUtil/Orders/Orders';

export * from 'SourceUtil/Orders/Orders';

export const statusesLabels = {
    invoice: {
        C: __('settled'),
        O: __('unsettled'),
        '-1': __('canceled')
    },
    order: {
        C: __('closed'),
        O: __('open'),
        '-1': __('canceled')
    }
};

export const statusesMods = {
    invoice: {
        C: 'settled',
        O: 'unsettled',
        '-1': 'canceled'
    },
    order: {
        C: 'closed',
        O: 'open',
        '-1': 'canceled'
    }
};

/** @namespace Eduksiazka/Util/Orders/getStatusLabel */
export const getStatusLabel = (value, type) => (value ? statusesLabels[type][value] : '');

/** @namespace Eduksiazka/Util/Orders/getStatusMod */
export const getStatusMod = (value, type) => (value ? statusesMods[type][value] : '');

/** @namespace Eduksiazka/Util/Orders/formatOrders */
export const formatOrders = (orders) => orders.reduceRight((acc, order) => {
    const { order_date, id } = order;
    const formattedDate = getFormattedDate(order_date);

    return [
        ...acc,
        {
            ...order,
            id,
            created_at: formattedDate
        }
    ];
}, []);
