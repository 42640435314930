/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export * from 'SourceComponent/Header/Header.config';

export const WISHLIST_URL = '/customer/account/my-wishlist';

export const MY_ACCOUNT_MENU_PAGE = 'MY_ACCOUNT_MENU_PAGE';
export const CUSTOMER_INVOICE = 'customer_invoice';
