/* eslint-disable spaced-comment,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Field as SourceField } from 'SourceComponent/Field/Field.component';

import './Field.override.style';

/**
 * Field
 * @class Field
 * @namespace Eduksiazka/Component/Field/Component */
export class Field extends SourceField {}

export default Field;
