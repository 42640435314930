/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { CartQuery as CartQuerySource } from 'SourceQuery/Cart.query';
import { Field } from 'Util/Query';

/** @namespace Eduksiazka/Query/Cart/Query */
export class CartQuery extends CartQuerySource {
    getClearCartMutation(cartId) {
        return new Field('clearCart')
            .addArgument('cart_id', 'String!', cartId)
            .addField(new Field('cart').addField('id'));
    }

    _getCartTotalsFields() {
        return [
            ...super._getCartTotalsFields(),
            'free_shipping'
        ];
    }

    getImportProductsToCartMutation(products_data) {
        return new Field('importProductsToCart')
            .addArgument('products_data', 'String!', products_data);
    }
}

export default new CartQuery();
