/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import CustomerProductListQuery from 'Query/CustomerProductList.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountTabListContainer as SourceMyAccountTabListContainer
} from 'SourceComponent/MyAccountTabList/MyAccountTabList.container';
import { updateProductsCount } from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery, getErrorMessage } from 'Util/Request';

/** @namespace Eduksiazka/Component/MyAccountTabList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showErrorNotification: (error) => dispatch(showNotification('error', getErrorMessage(error))),
    updateProductsCount: (productsCount) => dispatch(updateProductsCount(productsCount))
});

/** @namespace Eduksiazka/Component/MyAccountTabList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device,
    productsCount: state.MyAccountReducer.productsCount
});

/** @namespace Eduksiazka/Component/MyAccountTabList/Container */
export class MyAccountTabListContainer extends SourceMyAccountTabListContainer {
    componentDidMount() {
        this.updateProductsCount();
    }

    async updateProductsCount() {
        const { updateProductsCount } = this.props;

        try {
            const { countProductsInList: productsOnList } = await fetchQuery(CustomerProductListQuery.getProductListCount());
            updateProductsCount(productsOnList);
        } catch (e) {
            getErrorMessage(e);
        }
    }

    containerProps() {
        const { device, productsCount } = this.props;

        return {
            ...super.containerProps(),
            device,
            productsOnList: productsCount
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountTabListContainer);
