/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { GET_INVOICE_LIST, SET_INVOICE_LOADING_STATUS } from './Invoice.action';

/** @namespace Eduksiazka/Store/Invoice/Reducer/getInitialState */
export const getInitialState = () => ({
    invoiceList: {},
    isLoading: true
});

/** @namespace Eduksiazka/Store/Invoice/Reducer/InvoiceReducer */
export const InvoiceReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        invoiceList,
        status
    } = action;

    switch (type) {
    case GET_INVOICE_LIST:
        const { items = [], page_info } = invoiceList;

        return {
            ...state,
            isLoading: status,
            invoiceList: { items, pageInfo: page_info }
        };
    case SET_INVOICE_LOADING_STATUS:
        return {
            ...state,
            isLoading: status
        };
    default:
        return state;
    }
};

export default InvoiceReducer;
